<template>
    <div class="sidebar checkout" id="innr_sidebar" :class="{ active : modelValue }">
        <button type="button" class="close_btn" @click="closeSideBar()"><img src="@/assets/images/bar.svg"></button>
        <ul>
            <li><router-link :to="{name: 'CheckoutIndex'}">Dashboard</router-link></li>
            <li><router-link :to="{name: 'CheckoutProducts'}">Products</router-link></li>
            <li><router-link :to="{name: 'CheckoutClients'}">Clients</router-link></li>
            <li><router-link :to="{name: 'CheckoutOrders'}">Orders</router-link></li>
            <li><router-link :to="{name: 'CheckoutSubscriptions'}">Subscriptions</router-link></li>
            <li><router-link :to="{name: 'CheckoutLogs'}">Logs <!-- <a class="nmbr">6</a>--></router-link></li>
            <li v-if="user.is_organization_owner || user.is_company_location"><a @click="emailSetup = true" class="pointer">Email</a></li>
            <li v-if="user.is_organization_owner || user.is_company_location"><a @click="settingSetup = true" class="pointer">Settings</a></li>
        </ul>
        <!-- <span @click="toggleSidebar" id="toggle_sidebar" :class="{'swing' : showSidebar}"><i class="fas fa-chevron-right"></i></span> -->
    </div>

    <EmailSetup v-model="emailSetup" ref="setting-comp" />
    <setting-component v-model="settingSetup" ref="setting-comp" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapMutations } from 'vuex'

    const EmailSetup = defineAsyncComponent(() => import('@/pages/checkout/components/EmailSetup'))
    const SettingComponent = defineAsyncComponent(() => import('@/pages/checkout/components/Setting'))

    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Checkout Sidebar',

        data () {
            return {
                showSidebar: true,
                emailSetup: false,
                settingSetup: false,
            }
        },

        components: {
            EmailSetup,
            SettingComponent
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        watch: {
            checkoutAuthToken (token) {
                const vm = this;

                if (token) {
                    vm.getUserLocations();
                    vm.getDefaultTemplates();
                    vm.getProcessors();
                    vm.getCompanySettings();
                }
            },

            user (user) {
                const vm = this;

                if (user.id && (!user.checkout_currency_term_agreed || !user.has_checkout_processor) && (vm.user.is_organization_owner || vm.user.is_company_location)) {
                    vm.settingSetup = true;
                }

                if (user.id && !vm.checkoutAuthToken) {
                    vm.createCheckoutToken({ email: user.email, account_id: user.id, access_token: user.access_token });
                }

            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            checkoutAuthToken: state => state.checkoutModule.checkoutAuthToken,
            isSidebarMounted: state => state.checkoutModule.isSidebarMounted,
        }),

        created () {
            const vm = this;

            if (vm.user.id && (!vm.user.checkout_currency_term_agreed || !vm.user.has_checkout_processor) && (vm.user.is_organization_owner || vm.user.is_company_location)) {
                vm.settingSetup = true;
            }
        },

        mounted () {
            const vm = this;

            if (vm.user.id && (!vm.user.checkout_currency_term_agreed || !vm.user.has_checkout_processor) && (vm.user.is_organization_owner || vm.user.is_company_location)) {
                vm.settingSetup = true;
            }

            if (!vm.checkoutAuthToken && vm.user.id) {
                vm.createCheckoutToken({ email: vm.user.email, account_id: vm.user.id, access_token: vm.user.access_token });
            }

            if (vm.checkoutAuthToken && !vm.isSidebarMounted) {
                vm.getUserLocations();
                vm.getDefaultTemplates();
                vm.getProcessors();
                vm.getCompanySettings();

                vm.setisSidebarMounted(true);
            }

            const params  = new URLSearchParams(location.search);
            const tab     = params.has('tab') ? params.get('tab') : null;
            const status  = params.has('status') ? params.get('status') : null;
            const message = params.has('message') ? params.get('message') : null;

            if (status == 'success' && message) {
                Toastr.success(message);
            } else if (status == 'error' && message) {
                Toastr.error(message);
            }

            if (tab) {
                setTimeout(function () {
                  vm.settingSetup = true;

                  const settingComponent = vm.$refs['setting-comp'];

                  if (settingComponent) {
                      settingComponent.tab = tab;
                  }
                }, 100);
            }

            vm.myEventHandler();
            window.addEventListener("resize", vm.myEventHandler);
        },

        methods: {
            ...mapActions({
                createCheckoutToken: 'checkoutModule/createCheckoutToken',
                getUserLocations: 'checkoutModule/getUserLocations',
                getDefaultTemplates: 'checkoutModule/getDefaultTemplates',
                getProcessors: 'checkoutModule/getProcessors',
                getCompanySettings: 'checkoutModule/getCompanySettings',
            }),

            ...mapMutations({
                setisSidebarMounted: 'checkoutModule/SET_IS_SIDEBAR_MOUNTED',
            }),

            myEventHandler () {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 1199) {
                    vm.showSidebar = false;
                } else if(screenWidth < 992) {
                    vm.showSidebar = false;
                } else {
                    vm.showSidebar = true;
                }
            },

            toggleSidebar() {
                const vm = this;
                vm.showSidebar = !vm.showSidebar;
            },

            closeSideBar () {
                const vm = this;
                vm.$emit('update:modelValue', false);
            }
        },
    }
</script>
<style scoped>
    #toggle_sidebar {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #787878;
        font-size: 10px;
        color: #fff;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 87px;
        top: 335px;
        margin-left: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s ease-in-out;
        z-index: 12;
    }

    .close_btn {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #dfdfdf;
        display: none;
        align-items: center;
        justify-content: center;
        color: #2f7eed;
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 9;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
    }
    .close_btn img {
        max-width: 14px;
        height: auto;
        transform: scaleX(-1);
    }
    #toggle_sidebar.swing {
        transform: rotate(-180deg);
    }
    #innr_sidebar.checkout{
        width: 150px;
        flex-shrink: 0;
        position: sticky;
        top: 60px;
        margin-left: -20px;
    }
    .sidebar::before, .sidebar::after {
        display: none;
    }

    @media(max-width: 1199px){
        #innr_sidebar.checkout{
            position: fixed;
            width: 180px;
            left: -180px;
            z-index: 12;
            margin: 0;
            top: 0;
            height: 100vh;
            border-radius: 0;
            border-right: 1px solid #f5f5f5;
            background: #fff;
            padding: 45px 10px 0 10px;
        }
        #innr_sidebar.checkout.active{
            left: 0;
        }
        .close_btn{
            display: flex;
        }
    }
    @media(min-width: 1200px){
        #innr_sidebar.checkout.active{
            position: static;
        }
    }
</style>
